$main-background-dark: #171820;
$main-background-light: #ececec;
$main-background-light-secondary: darken($main-background-light, 10%);
$accent-blue: #2d76f5;
$main-background-dark-inverted: lighten($main-background-dark, 25%);
$main-background-dark-inverted-secondary: lighten($main-background-dark-inverted, 10%);
$neutral-white: #f5f5f5;

html, body {
	margin: 0;
	padding: 0;
	font-family: 'Roboto', sans-serif !important;
}

body *:not(.icon):not(.fa):not(.fas):not(.far):not(.fab):not([class^="devicon-"]):not(.material-icons) {
    font-family: 'Roboto', sans-serif !important;
}

html {
    scroll-behavior: smooth;
}
 
.show-sustem-cursor {
    cursor: auto;
}