@import '../index.scss';

.ui.grid.skills-container {
    padding-top: 15px;
}

.ui.segment.skills-segment {
    background: transparent;
    border: 2px solid #fff;
}

.bio-text {
    margin-bottom: 1em;
    text-align: left;
    font-size: calc(18px + 0.2vw);
    color: $main-background-light-secondary;
}

.bio-text-emphasized {
    font-size: calc(26px + 0.2vw);
}

@media screen and (max-width: 499px) {
    .bio-text-emphasized {
        font-size: calc(24px + 0.2vw);
    }
}

.bio-text-paragraph {
    margin-bottom: .75em;
}

.bio-text-inverted {
    color: $main-background-dark-inverted-secondary;
}

.bio-text-highlight {
    color: $accent-blue;
}

.skills-container-mobile .bio-text {
    font-size: calc(14px + 0.2vw)
}

.about-section-icons-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.skills-icons-container {
    display: flex;
    flex-wrap: wrap; 
    gap: 1rem; 
}

@mixin skills-icon-container {
    background: $neutral-white;
    margin: 0 !important;
    border-radius: 5em;
    display: flex;
    vertical-align: middle;
}

.ui.label.skills-icon-container {
    @include skills-icon-container;
    border: 2px solid $accent-blue;
    background-color: $accent-blue;
    color: $neutral-white;
}

@media screen and (max-width: 767px) {
    .ui.label.skills-icon-container {
        margin: -.25em -.25em 0 0 !important;
    }
}

.ui.label.skills-icon-container:hover {
    @include skills-icon-container;
    border: 2px solid $neutral-white;
    background-color: $neutral-white;
    color: $accent-blue;
}

.ui.label>.icon.skills-icon {
    margin-right: .25em;
}

.skills-container-inverted .ui.label.skills-icon-container:hover {
    @include skills-icon-container;
    border: 2px solid $main-background-dark-inverted;
    background-color: $neutral-white;
    color: $accent-blue;
}
