@import '../../index.scss';

.hamburger-button {
	outline: none;
	border: none;
	width: 3em;
	height: 2em;
	position: relative;
	z-index: 10;
	background-color: transparent;
	background-image: url("data:image/svg+xml,%3Csvg width='800px' height='800px' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4 5C3.44772 5 3 5.44772 3 6C3 6.55228 3.44772 7 4 7H20C20.5523 7 21 6.55228 21 6C21 5.44772 20.5523 5 20 5H4ZM7 12C7 11.4477 7.44772 11 8 11H20C20.5523 11 21 11.4477 21 12C21 12.5523 20.5523 13 20 13H8C7.44772 13 7 12.5523 7 12ZM13 18C13 17.4477 13.4477 17 14 17H20C20.5523 17 21 17.4477 21 18C21 18.5523 20.5523 19 20 19H14C13.4477 19 13 18.5523 13 18Z' fill='%23216ef4'/%3E%3C/svg%3E");
	background-size: 3em !important;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: contain;
	-webkit-tap-highlight-color: transparent;
	margin-right: -.4em;

	&.open {
		background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 13 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1l11 11m0-11L1 12' stroke='%23216ef4' stroke-width='2' stroke-linecap='round'/%3E%3C/svg%3E");
		background-size: 2em !important;
	}
}
  
.panel {
    max-width: 60%;
    background-color: rgba(120, 120, 120, 0.8);
    backdrop-filter: blur(7px); 
    background-size: 117px 188px;
    transition: all 0.4s cubic-bezier(0.42, 0, 0.34, 1.01);
    position: fixed;
    z-index: 5;
    right: -100%;
    top: 0;
    height: 100%;
    width: 90%;
    visibility: hidden;
    opacity: 0;
    padding: 1.5em;
    display: flex;
  
    &.open {
		visibility: visible;
		width: 100%;
		right: 0;
		opacity: 1;
    }
}

  
.ui.list.vertical-menu-items-list {
    margin: 3em auto auto auto !important;
    width: 100%;
    padding: 0 1em;

    .item {
        list-style: none;
        font-size: 1.5em;
        text-align: left;
        & + .item {
            margin-top: 0.5em;
        }

        .dropdown-menu-item-text {
            color: lighten($main-background-light, 10%);
            display: block;
        }
    }
}

@media screen and (max-width: 600px) {
    .panel {
        padding: 1em;
    }

    .ui.list.vertical-menu-items-list .item {
        font-size: 1.25em; 
    }
}



  