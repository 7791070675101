@import '../../index.scss';

$explore-button-dark: darken($main-background-dark-inverted, 5%);

.ui.inverted.button.explore-button {
    color: $accent-blue !important;
    box-shadow: 0 0 0 2px $main-background-light inset !important;
}

.ui.inverted.button.explore-button:hover,
.ui.inverted.button.explore-button:focus,
.ui.inverted.button.explore-button:visited {
    background: $main-background-light;
    box-shadow: 0 0 0 2px $main-background-light inset !important;
}

.ui.inverted.button.explore-button-inverted {
    background: transparent;
    color: $accent-blue !important;
    box-shadow: 0 0 0 2px $explore-button-dark inset !important;
}

.ui.inverted.button.explore-button-inverted:hover,
.ui.inverted.button.explore-button-inverted:focus,
.ui.inverted.button.explore-button-inverted:visited {
    box-shadow: 0 0 0 2px $explore-button-dark inset !important;
    background: $explore-button-dark;
}

.ui.inverted.button.explore-button-inverted:focus,
.ui.inverted.button.explore-button-inverted:visited {
    box-shadow: 0 0 0 2px $explore-button-dark inset !important;
    background: $explore-button-dark;
}

.ui.inverted.button.explore-button-intro {
    margin-top: 2em;
}
