@import '../index.scss';

.college-info-container {
    padding-top: 15px;
    display: flex;
    justify-content: center;
}

.ui.header.education-subheader {
    font-size: 2em;
}

.ui.header.header-label {
    margin-top: 0.25em;
}

.ui.card.school-card {
    width: calc(46vw + 2em);
    margin-bottom: 3em;

    .content {
        .header {
                color: $main-background-dark-inverted;
                font-size: 2em !important;
            }

        .meta {
            color: lighten($main-background-dark, 50%);
            font-size: 1.25em;
        }

        .description {
            color: $main-background-dark-inverted-secondary;
            font-size: 1.25em;
            text-align: left;
            line-height: 1.2em;
        }

        .school-description-link {
            color: $accent-blue;

            &:hover {
                text-decoration: underline;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .ui.card.school-card {
        width: calc(75vw + 2em);

        .content {
            .header {
                color: $main-background-dark-inverted;
                font-size: 1.5em !important;
            }

            .meta,
            .description {
                font-size: 1em;
            }
        }
    }    
}

.school-image-container {
    position: relative;
    width: 100%;
    height: 0;
    padding-top: calc(100% / 16 * 9); 
    overflow: hidden;
}

.school-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    filter: grayscale(100%);
    transition: filter .5s ease-in-out, opacity 1.5s ease-in-out; 
}

.school-image.active {
    opacity: 1;
}

.school-image-container::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(45, 118, 245, 0.5); 
    transition: opacity 0.5s;
    z-index: 1;
}

.school-image-container:hover .school-image {
    filter: none;
}

.school-image-container:hover::before {
    opacity: 0;
}

.school-image-container.toggled .school-image {
    filter: none;
}

.school-image-container.toggled::before {
    opacity: 0;
}

@media (hover: none) {
    .school-image-container::before {
        opacity: 1;
    }
}