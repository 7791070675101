@import '../index.scss';

.top-nav-link {
    height: 100%;
    text-align: center;
    transition: opacity 0.5s ease !important;
}

.item-text-container {
    display: flex;
    align-items: center;
}

.item-number {
    color: $accent-blue;
}

.ui.inverted.menu a.top-nav-link.item:hover,
.ui.menu a.top-nav-link.item:hover {
    background: transparent;
}

.ui.inverted.menu.top-nav-fixed .active-top-nav-link-text {
    color: $accent-blue !important;
}

.ui.inverted.menu.top-nav-fixed {
    justify-content: center;
    background: $main-background-light;
    transition: transform 0.2s ease;
    border-bottom: 1px solid $main-background-dark;
}

.ui.inverted.menu.top-nav-fixed.visible {
    transform: translateY(0);
}

.ui.inverted.menu.top-nav-fixed.hidden {
    transform: translateY(-100%);
}

.ui.inverted.menu.top-nav-fixed .top-nav-link {
    color: $main-background-dark;
}

.ui.inverted.menu.top-nav-fixed-inverted {
    background: $main-background-dark;
    border-bottom: 1px solid $main-background-light;
}

.ui.inverted.menu.top-nav-fixed {
    @media screen and (max-width: 767px) {
        .top-nav-link {
            padding: .75em .9225em;
        }
    }
}

.top-nav-link-text {
    display: block;
    position: relative;
    padding: .45em 0;
    color: $main-background-light;
}

a:hover .top-nav-link-text {
    color: $accent-blue !important;
}

.dimmed {
    opacity: 50%;
    transition: opacity 0.3s ease;
}

.ui.inverted.menu.top-nav-fixed .top-nav-link-text {
    color: $main-background-dark-inverted;
}

.ui.inverted.menu.top-nav-fixed .top-nav-link-text:hover {
    color: $accent-blue;
}

.ui.inverted.menu.top-nav-fixed-inverted .top-nav-link-text {
    color: $main-background-light;
}

.top-nav-link-text-inverted:hover {
    color: $accent-blue;
}

.ui.inverted.menu.top-nav-fixed-mobile {
    height: 4.5em;
}

.ui.inverted.menu.top-nav-fixed-mobile .top-nav-link .icon {
    margin: 0;
}

@media screen and (max-width: 499px) {
    .ui.inverted.menu.top-nav-fixed-mobile .top-nav-link {
        padding: .33em;
    }
}

.ui.menu .active.item.top-nav-link,
.ui.menu .active.item.top-nav-link:hover {
    background: transparent;
    pointer-events: none;
}

@media screen and (max-width: 795px) {
    .top-nav-link:hover {
        background: transparent !important;
    }
}

.ui.menu .item.top-nav-link-fixed {
    padding: .75em .923em;
}