@import '../../index.scss';

.ui.grid>.row .job-accordion-row {
    padding-top: 0;
}

.ui.header.job-accordion-header {
    color: $main-background-light-secondary;
}

.ui.header.job-accordion-header:hover,
.content-row-inverted .ui.header.job-accordion-header:hover {
    color: $accent-blue;
}

.ui.styled.accordion.job-accordion {
    background: $neutral-white;
}

.job-accordion-list {
    text-align: left;
    color: $main-background-dark-inverted;
}

.ui.grid.trailing-job-accordion {
    margin-top: 3em;
}

.job-title-text-divider {
    color: lighten($main-background-dark-inverted-secondary, 10%);
}

.job-dates-text {
    font-size: 0.9em;
}

.job-accordion .chevron {
    color: $accent-blue;
}

.open-accordion-title .job-title-text,
.open-accordion-title .job-dates-text {
    color: $accent-blue;
}

.closed-accordion-title .job-title-text,
.closed-accordion-title .job-dates-text {
    color: $main-background-dark-inverted;
}

.closed-accordion-title:hover,
.experience-row-inverted .closed-accordion-title:hover {
    .job-title-text,
    .job-dates-text {
        color: $accent-blue;
    }
}

.ui.styled.accordion.job-accordion {
    width: 60%;
    margin: 0 auto; 
}

.content-row-inverted .ui.header.job-accordion-header {
    color: $main-background-dark-inverted-secondary;
}

.ui.styled.accordion.job-accordion {
    font-size: 1.2em; 
}

.ui.grid>.row .job-header-row {
    padding-bottom: .5em;
}

.ui.list>.item.job-bullet {
    line-height: 1.2em;
}

@media screen and (max-width: 1024px) {
    .ui.styled.accordion.job-accordion {
        width: 80%; 
    }
}

@media screen and (max-width: 767px) {
    .ui.styled.accordion.job-accordion {
        width: 90%; 
    }
}

@media screen and (max-width: 480px) {
    .ui.styled.accordion.job-accordion {
        width: 95%; 
    }
}

@media screen and (max-width: 1024px) {
    .ui.styled.accordion.job-accordion .title, 
    .ui.styled.accordion.job-accordion .content {
        font-size: .9em;
    }
}

@media screen and (max-width: 767px) {
    .ui.styled.accordion.job-accordion .title,
    .ui.styled.accordion.job-accordion .content {
        font-size: .8em;
    }
}

@media screen and (max-width: 480px) {
    .ui.styled.accordion.job-accordion .title, 
    .ui.styled.accordion.job-accordion .content {
        font-size: .75em;
    }
}