@import '../../index.scss';

.ui.header.section-header {
    position: relative;
    color: $main-background-light;
    position: relative;
    display: inline-block;
    margin-bottom: .33em !important;
}

.ui.header.section-header-inverted {
    color: $main-background-dark-inverted;
}

.section-header::after {
    content: "";
    position: absolute;
    bottom: 6px; 
    left: -5px; 
    width: calc(100% + 10px);
    height: 15px; 
    background-color: $accent-blue;
    z-index: -1;
}

.ui.grid>.centered.row.content-row-mobile,
.ui.grid>.centered.row.content-row-mobile-inverted {
    .section-header {
        font-size: 2.75em;
    }

    .section-header::after {
        bottom: 6px; 
        height: 12px; 
    }
}
