@import '../../index.scss';

%logo-styles {
    font-weight: 600;
    position: relative;
    display: inline-block;
    font-size: 2.75em;
    font-weight: bold;
    padding: .4em;
    color: $accent-blue;
}

@mixin border-mixin($border-width: 4px, $border-color: $accent-blue) {
    border-width: $border-width;
    border-style: solid;
    border-image-slice: 1;
    border-color: $border-color;
}

%logo-border {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}

.logo-text-clickable {
    margin-left: .5em;
}

.logo-text-clickable,
.logo-text-static {
    @extend %logo-styles;
}

.logo-text-static::before,
.logo-text-clickable::before,
.logo-text-clickable-inverted::before {
    @extend %logo-border;
}

.logo-text-clickable::before,
.logo-text-clickable-inverted::before,
.footer-logo,
.bottom-nav-container-inverted .footer-logo,
.logo-text-clickable:hover::before  {
    @include border-mixin();
}

.logo-text-clickable:hover {
    background: $main-background-light;
}

.logo-text-clickable-inverted:hover {
    background: $main-background-dark-inverted;
}

.logo-text-clickable,
.bottom-nav-container-inverted .footer-logo {
    outline: 3px solid $main-background-light;
}

.logo-text-clickable-inverted,
.footer-logo {
    outline: 3px solid $main-background-dark-inverted;
}

