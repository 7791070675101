@import '../index.scss';

.ui.grid>.row.contact-icons-row {
    margin-top: 2em;
}

.ui.list.contact-icons-list-container {
    margin-top: .25em;
}

i.icon.contact-link {
    font-size: 1.5em;
}

.bottom-nav-container {
    margin-top: 0 !important;
    padding-top: 1em;
    background: $main-background-light;
    transition: background-color .3s ease-in-out;
}

.bottom-nav-container-inverted {
    background: $main-background-dark;
    transition: background-color .3s ease-in-out;
}

.ui.grid>.row.scroll-up-botton-container {
    margin-top: 2em !important;
    padding-bottom: 2em !important;
    overflow: hidden;
}


.explore-button-footer-row {
    margin: 2em !important;
}

.contact-links-container {
    padding: 0 !important;
    margin-top: 8em !important;
}

.copyright-text {
    color: $main-background-dark-inverted;
    padding-bottom: 2em !important;
}

.ui.list>a.item i.icon.contact-link-icon {
    color: $accent-blue;
}

.contact-link-text {
    color: $main-background-dark-inverted;
}

.contact-link-text-inverted,
.bottom-nav-container-inverted .copyright-text {
    color: $main-background-light;
}

.contact-link-text:hover {
    color: $accent-blue;
}

.contact-icons-list-item  {
    &:hover .contact-link-span {
        color: $accent-blue;
    }
}

@media only screen and (max-width: 767px) {
    .contact-links-container {
        padding: 0 !important;
        margin-top: 8em !important;
    }

    .copyright-text {
        margin-top: 1em !important;
        padding-bottom: 2em !important;
    }
    
    .ui.grid>.row.contact-icons-row {
        margin-top: 2em !important;
    }

    .scroll-up-botton-container {
        margin-bottom: 2em !important;
    }
}
