@import '../index.scss';

.top-nav-link {
    height: 100%;
    text-align: center;
    transition: opacity 0.5s ease !important;
}

.item-text-container {
    display: flex;
    align-items: center;
}

.item-number {
    color: $accent-blue;
}

.ui.inverted.menu a.top-nav-link.item:hover,
.ui.menu a.top-nav-link.item:hover {
    background: transparent;
}

.top-nav-link-text {
    display: block;
    position: relative;
    padding: .45em 0;
    color: $main-background-light;
}

a:hover .top-nav-link-text {
    color: $accent-blue !important;
}

.dimmed {
    opacity: 50%;
    transition: opacity 0.3s ease;
}

.ui.inverted.menu.top-nav-static .top-nav-link:hover {
    color: $accent-blue;
}

.top-nav-link-text-inverted:hover {
    color: $accent-blue;
}

.ui.menu.top-nav-static {
    height: 75px;
    transition: margin 0.5s ease, background 0.5s ease, box-shadow 0.5s ease, border 0.5s ease;
    justify-content: center;
    background: transparent !important;
    margin-bottom: 0;
}

.ui.menu .active.item.top-nav-link,
.ui.menu .active.item.top-nav-link:hover {
    background: transparent;
    pointer-events: none;
}

@media screen and (max-width: 795px) {
    .top-nav-link:hover {
        background: transparent !important;
    }
}

.top-nav-static-inverted .top-nav-link-text {
    color: $main-background-dark-inverted;
}

.ui.menu .item.top-nav-link-static-mobile {
    font-size: 2em;
    padding: 0;
}

.ui.menu .item.light-mode-menu-static-mobile-item {
    padding-right: 0;
}