@import '../index.scss';

.intro-container {
    height: calc(100vh - 103px);
    min-height: 700;
    padding: 15vh 0;
}

@media screen and (max-width: 767px) {
    .intro-container {
        padding: 10vh 0;
    }
}
 
.intro-main-text {
    font-size: 2.75em;
    color: $main-background-light;
}

.intro-sub-text {
    font-size: 1.5em;
}

.intro-sub-text-mobile {
    font-size: 1.25em;
}

.intro-sub-text,
.intro-sub-text-mobile {
    color: $main-background-light-secondary;
}

.background-container{
    position: fixed;
    top: 0;
    left:0;
    bottom: 0;
    right: 0;
}

.inverted-dark-text {
    color: $main-background-dark-inverted;
}

.intro-sub-text-inverted {
    color: $main-background-dark-inverted-secondary;
}

.intro-main-header-container {
    margin-bottom: 30px;
}
  
.ui.header:last-child.intro-main-header {
    margin: calc(2rem - .14285714em) 0 1rem;
}

@media screen and (max-width: 767px) {
    h1.ui.header.intro-main-header {
        font-size: 1.15em;
    }
}

.intro-main-text-mobile {
    font-size: 2.25em;
}

.intro-sub-text-mobile {
    font-size: 1em;
}

.intro-main-container-mobile .ui.header:last-child.intro-main-subheader {
    margin: 0;
}

