@import '../index.scss';

html {
    --cursor-color: #e0930d;
}
  
html.dark-mode {
    --cursor-color: #e0930d;
}

.ui.grid.content-rows-container {
    margin: 0;
    position: relative;
}

.intro-main-container {
    background: transparent;
}

.ui.grid>.row .intro-main-container {
    padding-top: 0;
}

.experience-row,
.experience-row-inverted {
    background: transparent;
}
.ui.header .sub.header>.sub-text-container {
    padding-top: 15px;
}

.ui.grid > .row.sub-row {
    padding: 5.5em 0;
    min-height: 100vh;
}

@media screen and (max-width: 767px) {
    .ui.grid > .row.sub-row {
        padding: 5.5em 0;
    }
}

.ui.grid > .row.sub-row h2.section-header {
    font-size: 3.5em;
}

.bold-text {
    font-weight: bold;
}

.education-row {
    background: transparent;
}

.ui.grid>.centered.row.content-row {
    background: transparent;
    position: relative;
}

%content-row-border {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 3px;
}

.content-row::before {
    @extend %content-row-border;
    background: linear-gradient(to right, transparent, $main-background-light, transparent);
}

.content-row-inverted::before {
    @extend %content-row-border;
    background: linear-gradient(to right, transparent, $main-background-dark-inverted, transparent);
}
.skills-row-inverted {
    background: transparent;
}

.ui.fitted.toggle.checkbox.color-slider {   
    position: fixed;
    margin: 30px;
    bottom: 0;
    right: 0;
}

.landing-container {
    position: relative;
}

.landing-content {
    position: absolute;
}

.fade-in {
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
}
  
  .fade-out {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
}

.header-row {
    background: transparent;
}

.header-row-landing .logo-container {
    margin-top: .25em;
}

.nav-container {
    padding-bottom: 0;
    padding-right: 2rem;
}

#contact-content {
    padding-bottom: 1em;
}

.particles-container {
    transition: opacity .3s ease-in-out;
  }
  
  .particles-hidden {
    opacity: 0;
  }
  
  .particles-visible {
    opacity: 1;
  }
